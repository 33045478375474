import React, { useState } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useTheme,
  Chip,
  Stack,
  LinearProgress,
  useMediaQuery,
} from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import TrendingDownRoundedIcon from '@mui/icons-material/TrendingDownRounded';
import TrendingFlatRoundedIcon from '@mui/icons-material/TrendingFlatRounded';
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded';
import ROIForm from '../components/Roi/RoiForm';
import Logo from '../components/Logo';

function RoiPage() {
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [selectedChip, setSelectedChip] = useState(0);
  const [roiData, setRoiData] = useState(null);
  const [formData, setFormData] = useState(null);
  const mobileHeaderStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    py: 3,
  };

  const defaultHeaderStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    py: 3,
  };

  const handleChipClick = (index) => {
    setSelectedChip(index);
  };

  const handleRoiSubmit = (data) => {
    setRoiData(data);
    console.log('Received ROI Data:', data);
  };
  const handlFromData = (data) => {
    setFormData(data);
    console.log('Received:', data);
  };
  const dataToShow = [
    {
      title: 'Annual',
      icon: <CalendarMonthRoundedIcon fontSize='small' />,
    },
    {
      title: 'High bill month',
      icon: <TrendingUpRoundedIcon fontSize='small' />,
    },
    {
      title: 'Moderate billl month',
      icon: <TrendingFlatRoundedIcon fontSize='small' />,
    },
    {
      title: 'Low bill month',
      icon: <TrendingDownRoundedIcon fontSize='small' />,
    },
  ];

  const VisualData = ({ data, roi_Bylease, roi_ByOwn }) => {
    const safeNumber = (value) => value ?? 0;

    const tableData = [
      {
        particular: 'Return on investment',
        doNothing: 0,
        getLease: safeNumber(roi_Bylease),
        ownPlant: safeNumber(roi_ByOwn),
      },
      {
        particular: 'Investment amount today',
        doNothing: 0,
        getLease: safeNumber(data?.lease?.investment),
        ownPlant: safeNumber(data?.own?.investment),
      },
      {
        particular: 'Savings',
        doNothing: 0,
        getLease: safeNumber(data?.lease?.savings),
        ownPlant: safeNumber(data?.own?.savings),
      },
      {},
      {
        particular: (
          <Typography variant='body1' fontWeight={'bold'}>
            Total energy expense
          </Typography>
        ),
        doNothing: (
          <Typography variant='body1' fontWeight={'bold'}>
            {Math.floor(
              (safeNumber(data?.do_nothing.bill_amount) /
                data?.do_nothing.bill_amount) *
                100,
            )}
            %
          </Typography>
        ),
        getLease: (
          <Typography variant='body1' fontWeight={'bold'}>
            {Math.floor(
              (safeNumber(data?.lease.total_energy_expense) /
                data?.do_nothing.bill_amount) *
                100,
            )}
            %
          </Typography>
        ),
        ownPlant: (
          <Typography variant='body1' fontWeight={'bold'}>
            {Math.floor(
              (safeNumber(data?.own.bill_amount) /
                data?.do_nothing.bill_amount) *
                100,
            )}
            %
          </Typography>
        ),
      },
      {
        particular: (
          <Typography variant='body1' fontWeight={'bold'}>
            Energy expense amount
          </Typography>
        ),
        doNothing: (
          <Typography variant='body1' fontWeight={'bold'}>
            {safeNumber(data?.do_nothing.bill_amount)}
          </Typography>
        ),
        getLease: (
          <Typography variant='body1' fontWeight={'bold'}>
            {safeNumber(data?.lease.total_energy_expense)}
          </Typography>
        ),
        ownPlant: (
          <Typography variant='body1' fontWeight={'bold'}>
            {safeNumber(data?.own.bill_amount)}
          </Typography>
        ),
      },
      {
        particular: 'Add: Fixed charges',
        doNothing: safeNumber(data?.do_nothing?.fixed),
        getLease: safeNumber(data?.lease?.fixed),
        ownPlant: safeNumber(data?.own?.fixed),
      },
      {
        particular: 'Add: Variable charges',
        doNothing: safeNumber(data?.do_nothing?.variable),
        getLease: safeNumber(data?.lease?.variable),
        ownPlant: safeNumber(data?.own?.variable),
      },
      {
        particular: 'Add: Solar charges',
        doNothing: 0,
        getLease: safeNumber(data?.lease?.lease_expense),
        ownPlant: 0,
      },
      {
        particular: 'Add: Taxes & surcharges',
        doNothing: safeNumber(data?.do_nothing?.tax),
        getLease: safeNumber(data?.lease?.tax),
        ownPlant: safeNumber(data?.own?.tax),
      },
      {
        particular: 'Less: Generation incentives',
        doNothing: 0,
        getLease: safeNumber(data?.lease?.incentives),
        ownPlant: safeNumber(data?.own?.incentives),
      },
      {},
      {
        particular: 'Units consumed',
        doNothing: safeNumber(data?.do_nothing?.units),
        getLease: safeNumber(data?.lease?.net_units),
        ownPlant: safeNumber(data?.own?.net_units),
      },
      {
        particular: 'Consumption expense per unit',
        doNothing: safeNumber(data?.do_nothing?.unit_rate),
        getLease: safeNumber(data?.lease?.unit_rate),
        ownPlant: safeNumber(data?.own?.unit_rate),
      },
      {
        particular: 'Units produced',
        doNothing: 0,
        getLease: safeNumber(data?.lease?.units_produced),
        ownPlant: safeNumber(data?.own?.units_produced),
      },
      {
        particular: 'Solar plant size',
        doNothing: 0,
        getLease: safeNumber(data?.lease?.plant_size),
        ownPlant: safeNumber(data?.own?.plant_size),
      },
    ];

    const chartData = [
      safeNumber(data?.investment_meter?.solar_lease * 100),
      safeNumber(data?.investment_meter?.solar_ownership * 100),
      safeNumber(data?.investment_meter?.equity * 100),
      safeNumber(data?.investment_meter?.debt * 100),
      safeNumber(data?.investment_meter?.fixed_deposit * 100),
      safeNumber(data?.investment_meter?.savings * 100),
      safeNumber(data?.investment_meter?.do_nothing),
    ];

    const newTableData = {
      1: { value: 10 },
      2: { value: 10 },
      3: { value: 9 },
      4: { value: 9 },
      5: { value: 9 },
      6: { value: 9 },
    };

    const valuesArray = Object.values(newTableData).map((item) => item.value);
    const minVal = Math.min(...valuesArray);
    const maxVal = Math.max(...valuesArray);
    const barColors = valuesArray.map((val) =>
      val === minVal
        ? '#c2c9d6'
        : val === maxVal
          ? theme.palette.secondary.light
          : null,
    );

    const chartLabels = [
      'Lease solar',
      'Buy solar',
      'Equity',
      'Debt',
      'FD',
      'Savings',
      'Do nothing',
    ];

    return (
      <Box gap={2} pb={2}>
        <Box mb={2}>
          <BarChart
            xAxis={[
              {
                scaleType: 'band',
                data: chartLabels,
                colorMap: {
                  type: 'ordinal',
                  colors: barColors,
                },
              },
            ]}
            series={[{ data: chartData }]}
            height={300}
          />
        </Box>
        <TableContainer
          component={Paper}
          sx={{ marginBottom: 4 }}
          pt={4}
          style={{ boxShadow: 'none' }}
        >
          <Table size='small' width='100%'>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>Particulars</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }} align='right'>
                  Do nothing
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold' }} align='right'>
                  Lease solar
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold' }} align='right'>
                  Buy solar
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((row) => (
                <TableRow hover key={row?.particular}>
                  <TableCell fontWeight={'bold'} component='th' scope='row'>
                    {row?.particular}
                  </TableCell>
                  <TableCell align='right'>{row?.doNothing}</TableCell>
                  <TableCell align='right'>{row?.getLease}</TableCell>
                  <TableCell align='right'>{row?.ownPlant}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };

  return (
    <>
      <Box
        component='header'
        sx={isMobile ? mobileHeaderStyle : defaultHeaderStyle}
      >
        <Logo />
      </Box>
      <Box sx={{ maxWidth: 800, margin: '0 auto', padding: 2 }}>
        <ROIForm onSubmit={handleRoiSubmit} formDate={handlFromData} />

        {roiData && (
          <>
            <Typography variant='h5' fontWeight={'bold'} mb={2} mt={2}>
              Estimated electricity expense
            </Typography>
            <Stack
              display='flex'
              flexDirection='row'
              justifyContent='flex-start'
              gap={1}
              mb={2}
              height={40}
              sx={{
                overflowX: 'auto',
                whiteSpace: 'nowrap',
                '&::-webkit-scrollbar': {
                  height: '6px',
                },
              }}
            >
              {dataToShow.map((data, i) => (
                <Chip
                  label={data.title}
                  icon={data.icon}
                  // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                  key={i}
                  color={selectedChip === i ? 'primary' : 'default'} // Primary color if selected
                  onClick={() => handleChipClick(i)} // Toggle selection on click
                  sx={{
                    borderRadius: '6em',
                    backgroundColor:
                      selectedChip === i ? 'primary.main' : 'white',
                    border:
                      selectedChip === i ? '1px solid green' : '1px solid gray',
                    color: selectedChip === i ? 'white' : 'gray',
                    '&:hover': {
                      backgroundColor:
                        selectedChip === i ? 'primary.dark' : 'gray.100',
                    },
                  }}
                />
              ))}
            </Stack>

            {selectedChip === 0 && (
              <VisualData
                data={roiData?.annual_bill_profile}
                roi_Bylease={roiData?.annual_bill_profile?.lease?.annual_roi}
                roi_ByOwn={roiData?.annual_bill_profile?.own?.annual_roi}
              />
            )}
            {selectedChip === 1 && (
              <VisualData
                data={roiData?.max_bill_profile}
                roi_Bylease={roiData?.max_bill_profile?.lease?.monthly_roi}
                roi_ByOwn={roiData?.max_bill_profile?.own?.monthly_roi}
              />
            )}
            {selectedChip === 2 && (
              <VisualData
                data={roiData?.mid_bill_profile}
                roi_Bylease={roiData?.mid_bill_profile?.lease?.monthly_roi}
                roi_ByOwn={roiData?.mid_bill_profile?.own?.monthly_roi}
              />
            )}
            {selectedChip === 3 && (
              <VisualData
                data={roiData?.min_bill_profile}
                roi_Bylease={roiData?.min_bill_profile?.lease?.monthly_roi}
                roi_ByOwn={roiData?.min_bill_profile?.own?.monthly_roi}
              />
            )}
          </>
        )}
      </Box>
    </>
  );
}

export default RoiPage;
