import React, { useState } from 'react';
import {
  TextField,
  Grid,
  Typography,
  MenuItem,
  Box,
  LinearProgress,
  Slide,
  Modal,
  Button,
  Stack,
  useMediaQuery,
  CircularProgress,
  IconButton,
  Menu,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { roi, s3PresignedUrl } from '../../services/CustomerService';
import { useTheme } from '@emotion/react';
import SaveIcon from '@mui/icons-material/Save';
import ShareIcon from '@mui/icons-material/Share';
import LinkIcon from '@mui/icons-material/Link';
import { Facebook, Twitter, Email, WhatsApp } from '@mui/icons-material';

const ROIForm = ({ onSubmit }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [shareButton, setShareButton] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [shareLink, setShareLink] = useState('');
  const [data, setData] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [formData, setFormData] = useState({
    propertyType: '',
    state: '',
    maxBill: '',
    minBill: '',
    load: '',
    customPlantSize: '',
    pdfFlag: '1',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // const handleSubmitPdfFlagset1 = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await roi({ ...formData, pdfFlag: '1' });
  //     setData(response);
  //     setShareLink(response?.pdf_url);
  //     setLoading(false);
  //   } catch (error) {
  //     alert('Failed to download PDF.');
  //     setLoading(false);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const response = await roi(formData);
      onSubmit(response);
      setLoader(false);
      setShareLink(response?.pdf_url);
      setData(response);
      setShareButton(formData);
    } catch (error) {
      console.error('Error submitting data:', error);
      alert('Failed to submit data.');
      setLoader(false);
    }
  };
  console.log('data', data);
  console.log('shareLink', shareLink);

  const downloadPdf = async () => {
    const pdfUrl = await s3PresignedUrl(data?.pdf_url);
    window.open(pdfUrl?.url);
  };

  const handleCloseAnchor = () => {
    setAnchorEl(null);
  };
  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareLink);
    setAnchorEl(null);
  };
  const shareOptions = [
    {
      icon: <Facebook sx={{ mr: '12px' }} />,
      label: 'Facebook',
      url: 'https://www.facebook.com/sharer/sharer.php?u=',
    },
    {
      icon: <Twitter sx={{ mr: '12px' }} />,
      label: 'Twitter',
      url: 'https://twitter.com/intent/tweet?url=',
    },
    {
      icon: <Email sx={{ mr: '12px' }} />,
      label: 'Email',
      url: 'mailto:?body=',
    },
    {
      icon: <WhatsApp sx={{ mr: '12px' }} />,
      label: 'WhatsApp',
      url: 'https://api.whatsapp.com/send?text=',
    },
  ];

  return (
    <>
      <Box sx={{ maxWidth: 600, margin: '0 auto', padding: 2 }}>
        <Typography variant='h5' mb={4} textAlign={'center'} fontWeight='bold'>
          Solar return on investment
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                size='small'
                fullWidth
                select
                label='Property type'
                name='propertyType'
                value={formData.propertyType}
                onChange={handleChange}
                required
              >
                <MenuItem value='Commercial'>Commercial</MenuItem>
                <MenuItem value='Residential'>Residential</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                size='small'
                fullWidth
                select
                label='State'
                name='state'
                value={formData.state}
                onChange={handleChange}
                required
              >
                <MenuItem value='Punjab'>Punjab</MenuItem>
                <MenuItem value='Uttar Pradesh'>Uttar Pradesh</MenuItem>
                <MenuItem value='Delhi'>Delhi</MenuItem>
                <MenuItem value='Karnataka'>Karnataka</MenuItem>
                <MenuItem value='Haryana'>Haryana</MenuItem>
                <MenuItem value='Haryana'>Bihar</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                size='small'
                fullWidth
                label='Max bill'
                name='maxBill'
                type='number'
                value={formData.maxBill}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size='small'
                fullWidth
                label='Min bill'
                name='minBill'
                type='number'
                value={formData.minBill}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size='small'
                fullWidth
                label='Load'
                name='load'
                type='number'
                value={formData.load}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size='small'
                fullWidth
                label='Custom plant size'
                name='customPlantSize'
                type='number'
                value={formData.customPlantSize}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Stack gap={2} flexDirection={'row'}>
                <Button
                  disabled={loader}
                  type='submit'
                  variant='outlined'
                  color='primary'
                >
                  Get estimates
                </Button>
                {shareButton && (
                  <>
                    <Button variant='outlined' onClick={handleOpen}>
                      Share report
                    </Button>
                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby='modal-title'
                      aria-describedby='modal-description'
                    >
                      <Box
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          bgcolor: 'background.paper',
                          boxShadow: 24,
                          p: 4,
                          borderRadius: 2,
                          width: '100%',
                          maxWidth: 500,
                          height: isMobile ? '100%' : 'auto',
                          maxHeight: '100vh',
                          overflowY: 'auto',
                        }}
                      >
                        <Typography
                          id='modal-title'
                          fontWeight='bold'
                          variant='h5'
                          mb={2}
                        >
                          Get solar savings report
                        </Typography>
                        <Grid
                          container
                          spacing={2}
                          width={isMobile ? '100%' : '50%'}
                        >
                          <Grid item xs={12}>
                            <TextField
                              size='small'
                              fullWidth
                              label='Customer name'
                              name='customerName'
                              // value={formData.customerName}
                              // onChange={handleChange}
                              required
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              size='small'
                              fullWidth
                              label='Customer phone'
                              name='customerPhone'
                              // value={formData.customerPhone}
                              // onChange={handleChange}
                              required
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              size='small'
                              fullWidth
                              label='Pin code'
                              name='pinCode'
                              // value={formData.pinCode}
                              // onChange={handleChange}
                              required
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              size='small'
                              fullWidth
                              label='Address'
                              name='address'
                              // value={formData.address}
                              // onChange={handleChange}
                              required
                            />
                          </Grid>
                        </Grid>
                        {loading ? (
                          <Stack
                            direction='row'
                            spacing={2}
                            justifyContent='flex-end'
                            mt={4}
                          >
                            <CircularProgress />
                          </Stack>
                        ) : (
                          <Stack
                            direction='row'
                            spacing={2}
                            justifyContent='flex-end'
                            mt={4}
                          >
                            <Button disabled={loading} onClick={handleClose}>
                              Cancel
                            </Button>

                            {/* {!shareLink && (

                              <Button
                                disabled={loading}
                                onClick={() => {
                                  setFormData({ ...formData, pdfFlag: '1' });
                                  handleSubmitPdfFlagset1();
                                }}
                                variant='contained'
                              >
                                Create report
                              </Button>
                            )} */}

                            {shareLink && (
                              <Button
                                disabled={loading}
                                onClick={() => {
                                  downloadPdf();
                                }}
                                variant='contained'
                                color='primary'
                                startIcon={<SaveIcon />}
                              >
                                Download report
                              </Button>
                            )}
                            {/* {shareLink && (
                              <>
                                <IconButton
                                  onClick={handleClick}
                                  color='primary'
                                >
                                  <ShareIcon />
                                </IconButton>
                                <Menu
                                  anchorEl={anchorEl}
                                  open={Boolean(anchorEl)}
                                  onClose={handleCloseAnchor}
                                >
                                  {shareOptions.map((option) => (
                                    <MenuItem
                                      key={option.label}
                                      onClick={() =>
                                        window.open(
                                          `${option.url}${encodeURIComponent('💡 Big savings on electricity bills! ☀ I can save ₹{annual_savings}/year and cut my electricity bill by {bill_reduction_percent}%! Plus, the ROI speaks for itself: ✅ Buying Solar: ~{solar_buying_roi}%/year 🌟 ✅ Leasing Solar: ~{solar_leasing_roi}%/year ❌ FDs: ~{fd_roi}%/year ❌ Mutual Funds: ~{mutual_funds_roi}%/year 🌍 Why wait? Start saving and investing in a sustainable future! 👉 Check my savings report here: ' + shareLink)}`,
                                          '_blank',
                                        )
                                      }
                                    >
                                      {option.icon}
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                  <MenuItem onClick={handleCopyLink}>
                                    <ListItemIcon>
                                      <LinkIcon />
                                    </ListItemIcon>
                                    <ListItemText>Copy Link</ListItemText>
                                  </MenuItem>
                                </Menu>
                              </>
                            )} */}
                          </Stack>
                        )}
                      </Box>
                    </Modal>
                  </>
                )}
              </Stack>
            </Grid>
          </Grid>
        </form>
      </Box>
      {loader && (
        <Box sx={{ width: '100%', mt: 3, maxWidth: 800 }}>
          <LinearProgress />
        </Box>
      )}
    </>
  );
};

export default ROIForm;
